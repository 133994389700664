import Keycloak from "keycloak-js";
import appConfig from "../../config"

const keycloak = new Keycloak({
  realm: "webapp", // realm as configured in Keycloak
  url: appConfig.IDENTITY_SERVER_URL,
  //url: "http://localhost:8080", // URL of the Keycloak server
  //url: "http://bl-demo.blsm.fr:8282", // URL of the Keycloak server
  clientId: "webapp-client" // client id as configured in the realm in Keycloak
});

export default keycloak;
