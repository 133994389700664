import request from "./requestConfig";

const prefix = "/appUser";

export function getAppUserByEmail(email) {
  return request.get(`${prefix}/email?email=${email}`);
}

export function getAppUserByObjectGUID(objectGUID) {
  return request.get(`${prefix}/guid?objectGUID=${objectGUID}`);
}

export default {
  getAppUserByEmail,
  getAppUserByObjectGUID
};
