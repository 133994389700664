import * as types from "./actionTypes";
import { hideLoading, setError, setLoading } from "./commonActions";
import appUserApi from "../../api/appUserApi";

export function fetchCurrentUser(email) {
  return async (dispatch) => {
    try {
      setLoading();
      const result = await appUserApi.getAppUserByEmail(email);
      if (result.status !== 200) {
        dispatch(setError(result));
        hideLoading();
        return { success: false, error: result };
      }

      dispatch({ type: types.LOAD_CURRENT_USER_SUCCESS, payload: result.data });
      hideLoading();
      return { success: true, error: "", data: result.data };
    } catch (error) {
      hideLoading();
      return { success: false, error: error.response?.data.message };
    }
  };
}

export function fetchCurrentUserByObjectGuid(objectGUID) {
  return async (dispatch) => {
    try {
      setLoading();
      const result = await appUserApi.getAppUserByObjectGUID(objectGUID);
      if (result.status !== 200) {
        dispatch(setError(result));
        hideLoading();
        return { success: false, error: result };
      }

      dispatch({ type: types.LOAD_CURRENT_USER_SUCCESS, payload: result.data });
      hideLoading();
      return { success: true, error: "", data: result.data };
    } catch (error) {
      hideLoading();
      return { success: false, error: error.response?.data.message };
    }
  };
}
