import * as types from "../actions/actionTypes";

const initialState = {
  isAuthenticated: false,
  currentUser: null
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case types.LOAD_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload
      };
    default:
      return state;
  }
}
