import PropTypes from "prop-types";
import React from "react";

import "./style.scss";

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    // console.log(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <div className="ErrorBoundary text-center">
        <h3>Something went wrong</h3>
      </div>
    ) : (
      this.props.children
    );
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node
};
