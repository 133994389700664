import React from "react";

export default function LoadingSpinner({ isLoading }) {
  const loadingComp = (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );

  return isLoading ? loadingComp : null;
}
