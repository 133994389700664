import Layout from "components/layout";
import PropTypes from "prop-types";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
const ProtectedRoute = ({ redirectPath = "/", children }) => {
  const token = localStorage.getItem("token");
  // console.log(token)
  // if (!token) {
  //   return <Navigate to={ redirectPath } replace />;
  // }

  return children ? (
    <Layout>{ children }</Layout>
  ) : (
    <Layout>
      {/* <div style={{padding:0, margin:0, border:'1px solid red'}}> */}
      <Outlet />
      {/* </div> */}
     
    </Layout>
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  redirectPath: PropTypes.string
};

export default ProtectedRoute;
