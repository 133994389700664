import React from "react";
import { render } from "react-dom";
import { Provider as ReduxProvider } from "react-redux";

import App from "./app";
import configureStore from "./redux/configureStore";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const store = configureStore();

render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
  document.getElementById("root")
);
reportWebVitals();
