import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { sendAskQuestionEmail } from "../../api/emailApi";
import { getFullName } from "../../shared/function";
import Notification from "../common/Notification";

const AskQuestionModal = ({ open, onClose }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [typeOfRequest, setTypeOfRequest] = useState("Information request");
  const [detail, setDetail] = useState("");
  const [notif, setNotif] = useState({
    display: false,
    message: "",
    severity: ""
  });
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setDetail("");
    setPhoneNumber("");
    setCompany("");
    onClose();
  };

  const handleSend = async () => {
    setLoading(true);

    const res = await sendAskQuestionEmail({
      name: getFullName(currentUser),
      email: currentUser.email,
      company,
      phoneNumber,
      typeOfRequest,
      detail
    });

    setNotif({
      display: true,
      message: res.data.message,
      severity: res.data.message?.includes("Error") ? "error" : "success"
    });

    setLoading(false);
    handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ask your question</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            defaultValue={getFullName(currentUser)}
            fullWidth
            size="small"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true
            }}
          />
          <TextField
            label="Email"
            defaultValue={currentUser?.email}
            fullWidth
            size="small"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true
            }}
          />
          <TextField
            label="Company"
            fullWidth
            size="small"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <TextField
            label="Phone Number"
            fullWidth
            size="small"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            label="Type of the request"
            required
            select
            fullWidth
            size="small"
            margin="normal"
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
            value={typeOfRequest}
            onChange={(e) => setTypeOfRequest(e.target.value)}
          >
            <option value="Information request">Information request</option>
            <option value="Incident">Incident</option>
            <option value="Support for the setting of parameters">
              Support for the setting of parameters
            </option>
          </TextField>
          <TextField
            label="Detail of the request"
            required
            multiline
            rows={4}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSend}
            color="primary"
            disabled={!detail}
            loading={isLoading}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Notification
        open={notif.display}
        severity={notif.severity}
        message={notif.message}
        onClose={() => setNotif({ open: false, message: "" })}
      />
    </>
  );
};

export default AskQuestionModal;
