import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import configApp from "../../config";

const LogoutPage = () => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      if (initialized && keycloak.authenticated) {
        try {
          // await keycloak.logout({ redirectUri: "http://localhost:3000/" });
          await keycloak.logout({ redirectUri: configApp.APP_URL });
          keycloak.clearToken();
          localStorage.removeItem("token");
          navigate("/login");
        } catch (error) {
          console.error("Error while logging out:", error);
        }
      } else {
        navigate("/login");
      }
    };

    logout();
  }, [initialized, keycloak, navigate]);

  return <></>;
};

export default LogoutPage;
