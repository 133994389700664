import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import React from "react";
import { Toaster } from "react-hot-toast";
import ErrorBoundary from "../components/errorBoundary";

import mdTheme from "./Theme";
import keycloak from "../components/keycloak";
import Root from "../routes";

const App = () => {
  return (
    <ErrorBoundary>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
          onLoad: "login-required"
        }}
      >
        <ThemeProvider theme={mdTheme}>
          <CssBaseline />
          <Root />
          <Toaster />
        </ThemeProvider>
      </ReactKeycloakProvider>
    </ErrorBoundary>
  );
};

export default App;
