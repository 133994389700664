import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import config from "../../config";

const ChangePassword = () => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      window.location.href = `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/auth?response_type=code&client_id=${keycloak.clientId}&redirect_uri=${config.APP_URL}/&kc_action=UPDATE_PASSWORD`;
    } else {
      navigate("/login");
    }
  }, [initialized, keycloak, navigate]);

  return <></>;
};

export default ChangePassword;
