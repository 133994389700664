import { Card, Stack, Typography, Grid, CardMedia, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useKeycloak } from "@react-keycloak/web";
import config from "config";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { agentImg, adminImg, contactImg, dashboardImg, contactnImg, dashboardnImg, agentnImg, adminnImg,
agentmImg, contactmImg, assetAgentImg, assetAdminImg
} from "../../assets/images"
import { GroupType, PlatformType } from "shared/enum";
import { fetchCurrentUser, fetchCurrentUserByObjectGuid } from "../../redux/actions/authActions";
import { getFullName } from "../../shared/function";
import './home.css'
import { data } from "jquery";

const useStyles = makeStyles({
  rootCard: {
    height: 245, width: 400, borderRadius:10, cursor: "pointer",
    // border: "1px solid rgba(0, 0, 0, 0.23)",
    border: '1px solid rgba(0, 0, 0, 0.23)',
    // boxShadow: "none",
    "&:hover": {
      borderColor: "#1488CC",
      borderWidth: "2px"
    }
  },
  portalCard: {
    height: 250,
    width: "80%",
    // maxWidth: "215px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 35px",
    fontSize: "22px",
    fontWeight: 900,
    cursor: "pointer",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "20px",
    filter: "drop-shadow(0px 2px 100px rgba(0, 0, 0, 0.10))",
    boxShadow: "none",
    margin: "0 auto",
    "&:hover": {
      borderColor: "#1488CC",
      borderWidth: "2px"
    }
  },
  portalImageContainer: {
    height: 200,
    flex: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "20px",
    borderRadius: "20px"
  },
  portalImage: {
    width: "100%",
    height: 200
    // marginBottom:5
  }
});

const HomePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const currentUser = useSelector((state) => state.auth.currentUser);

  const [selectedPortal, setSelectedPortal] = React.useState(null)
  const selectColor = "#1976d2"

  const hasGroup = useCallback(
    (groupType) => {
      if (!currentUser) {
        return false;
      }

      return (
        currentUser.groups.findIndex((group) => group.type === groupType) > -1
      );
    },
    [currentUser]
  );

  const handleGoToPlatform = (platform) => {
    if (!keycloak.token) {
      return;
    }

    switch (platform) {
      case PlatformType.Admin:
        if (
          keycloak &&
          // keycloak.hasResourceRole("admin") && 
          hasGroup(GroupType.Admin)
        ) {
          location.href =
            config.ADMIN_SITE_URL + `/dashboard?token=${keycloak.token}`;
        } else {
          toast.error("You are not able to visit Admin site.", {
            position: "top-right"
          });
        }
        break;
      case PlatformType.Agent:
        if (keycloak && 
          // keycloak.hasResourceRole("agent") &&
           hasGroup(GroupType.Agent)) {
          location.href = config.AGENT_SITE_URL + `/dashboard?token=${keycloak.token}`;
        }
        break;
      case PlatformType.Contact:
        if (keycloak && 
          // keycloak.hasResourceRole("contact") && 
          hasGroup(GroupType.Contact)) {
          location.href = config.CONTACT_SITE_URL + `/home?token=${keycloak.token}`;
        }
        break;
      case PlatformType.Dashboard:
        location.href = config.DASHBOARD_SITE_URL + `/home?token=${keycloak.token}`;
        break;
      
      case PlatformType.AssetAgent:
        location.href = config.ASSET_AGENT_SITE_URL + `/dashboard?token=${keycloak.token}`;
        break;
        case PlatformType.AssetAdmin:
          location.href = config.ASSET_ADMIN_SITE_URL + `/dashboard?token=${keycloak.token}`;
        break;
      
     
    }
  };

  const convertObjectId = (base64String) => {
    // Convert Base64 to binary
    const binaryData = atob(base64String);
    
    // Convert binary to hexadecimal
    let hexString = "";
    for (let i = 0; i < binaryData.length; i++) {
      hexString += ("00" + binaryData.charCodeAt(i).toString(16)).slice(-2);
    }
    console.log(hexString);
    // Format the hexadecimal string as a GUID
    const formattedGuid = [
     
      hexString.substr(6, 2) + hexString.substr(4,2) + hexString.substr(2,2)+hexString.substr(0,2) ,
      hexString.substr(10, 2) +  hexString.substr(8, 2),
      hexString.substr(14, 2) +hexString.substr(12, 2),
      hexString.substr(16, 4),
      hexString.substr(20, 12)
    ].join("-");
    return formattedGuid;
  } 

  useEffect(async () => {   

    //vérifier si je viens de keycloak ou de LDAP
    //si je viens de leycloak => il faut d"clencher l'api qui récuper le user via son mail/id
    //si je viens de LDAP => il faut déclencher l'api qui récupère le user via ObjectGUID
    console.log('--token :>>', keycloak.tokenParsed);
    let res;
    if (keycloak.tokenParsed?.ldap_id){
      let objectGuid = convertObjectId(keycloak.tokenParsed?.ldap_id)
      res =await dispatch(fetchCurrentUserByObjectGuid(objectGuid));
    }else if (keycloak.tokenParsed?.email) {
      res = await dispatch(fetchCurrentUser(keycloak.tokenParsed?.email));
    }



    if(res !== null ){
      if(!res?.data?.status){
        toast.error("Account is disabled, contact your administrator.", {
          position: "top-right"
        });
      }
     }

  }, [keycloak.tokenParsed]);

  const fontSize = 19
  const fontWeight = 650

  return (
    <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
    
        <Stack direction={"row"} useFlexGap flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", 
        
        
        padding: 5}} spacing={4}>
          {/* <Grid container rowGap={4} spacing={2}> */}
            {currentUser && currentUser.groups.find(x=>x.type===GroupType.Agent) && currentUser?.status && (
              // <Grid item xs={12} sm={4}>
                <Card variant="elevation" elevation={2} 
                  onMouseEnter={() => {setSelectedPortal(GroupType.Agent)}}
                  onMouseLeave={() => {setSelectedPortal(null)}}
                 className={classes.rootCard}               
                  onClick={() => handleGoToPlatform(PlatformType.Agent)}
                >
                  <CardMedia
                    component="img"
                    // sx={{ height: 140 }}
                    // height={140}
                    image={agentmImg}
                    // sx={{ objectFit: "contain" }}
                    sx={{ objectFit: "cover", 
                      width:'100%', 
                      height:'72%',
                    }}
                  />
                   <CardContent sx={{flex:1, textAlign:'center', pt:1.5, }}>
                    <Stack direction={"column"} spacing={0}>
                      <Typography sx={{pb:0, color: selectedPortal === GroupType.Agent ? selectColor : "default"}}
                       fontSize={fontSize} fontWeight={fontWeight}>
                        PORTAIL AGENT
                      </Typography>
                      <Typography variant="caption">
                        Traitement des demandes
                      </Typography>
                    </Stack>
                        
                  </CardContent>
                </Card>
              // </Grid>
            )}
            {currentUser && currentUser.groups.find(x=>x.type===GroupType.Admin) && currentUser?.status && (
              // <Grid item xs={12} sm={4}>
                <Card variant="elevation" elevation={2}
               
                onMouseEnter={() => {setSelectedPortal(GroupType.Admin)}}
                onMouseLeave={() => {setSelectedPortal(null)}} 
                className={classes.rootCard}
                  onClick={() => handleGoToPlatform(PlatformType.Admin)}
                >
                  <CardMedia
                    component="img"
                    sx={{ objectFit: "cover", 
                    width:'100%', 
                    height:'72%',
                   }}   
                    //  height={140}
                     image={adminnImg}
                     alt="Admin Image"
                       
                  />
                  <CardContent sx={{flex:1, textAlign:'center', pt:1.5, }}>
                    <Stack direction={"column"} spacing={0}>
                      <Typography sx={{pb:0,  fontWeight: 'bold', color: selectedPortal === GroupType.Admin ? selectColor : "default"}}
                       fontSize={fontSize} fontWeight={fontWeight} >
                        PORTAIL ADMIN
                      </Typography>
                      <Typography variant="caption">
                        Administration
                      </Typography>
                    </Stack>
                        
                  </CardContent>
                </Card>
              // </Grid>
             )}
             
             {currentUser && currentUser.groups.find(x=>x.type===GroupType.Contact) && currentUser?.status && (
              // <Grid item xs={12} sm={4}>
                <Card variant="elevation" elevation={2}
                  className={classes.rootCard}
                  onMouseEnter={() => {setSelectedPortal(GroupType.Contact)}}
                  onMouseLeave={() => {setSelectedPortal(null)}}             
                  onClick={() => handleGoToPlatform(PlatformType.Contact)}
                  // sx={{height:200}}
                  
                >
                  <CardMedia
                    component="img"
                    // height={80}
                    image={contactmImg}
                    sx={{ objectFit: "contain", 
                    width:'100%', 
                    height:'72%', 
                    }}   
                  />
                  <CardContent sx={{flex:1, textAlign:'center', pt:1.5, }}>
                    <Stack direction={"column"} spacing={0}>
                      <Typography sx={{pb:0,  fontWeight: 'bold', color: selectedPortal === GroupType.Contact ? selectColor : "default"}}
                       fontSize={fontSize} fontWeight={fontWeight}>
                        PORTAIL CONTACT
                      </Typography>
                      <Typography variant="caption">
                        Demandes Utilisateurs
                      </Typography>
                    </Stack>
                        
                  </CardContent>
                </Card>
              // </Grid>
              )}
              {/* <Grid item xs={12} sm={4}> */}
                <Card variant="elevation" elevation={2}
                  className={classes.rootCard}
                  onMouseEnter={() => {setSelectedPortal(GroupType.AssetAgent)}}
                  onMouseLeave={() => {setSelectedPortal(null)}}             
                  onClick={() => handleGoToPlatform(PlatformType.AssetAgent)}
                  // sx={{height:200}}
                  
                >
                  <CardMedia
                    component="img"
                    // height={80}
                    image={assetAgentImg}
                    sx={{ objectFit: "contain", 
                    width:'100%', 
                    height:'72%', 
                    }}   
                  />
                  <CardContent sx={{flex:1, textAlign:'center', pt:1.5, }}>
                    <Stack direction={"column"} spacing={0}>
                      <Typography sx={{pb:0,  fontWeight: 'bold', color: selectedPortal === GroupType.AssetAgent ? selectColor : "default"}}
                       fontSize={fontSize} fontWeight={fontWeight}>
                        PORTAIL ASSET AGENT
                      </Typography>
                      <Typography variant="caption">
                        Asset Management
                      </Typography>
                    </Stack>
                        
                  </CardContent>
                </Card>
                <Card variant="elevation" elevation={2}
                  className={classes.rootCard}
                  onMouseEnter={() => {setSelectedPortal(GroupType.AssetAdmin)}}
                  onMouseLeave={() => {setSelectedPortal(null)}}             
                  onClick={() => handleGoToPlatform(PlatformType.AssetAdmin)}
                  // sx={{height:200}}
                  
                >
                  <CardMedia
                    component="img"
                    // height={80}
                    image={assetAdminImg}
                    sx={{ objectFit: "contain", 
                    width:'100%', 
                    height:'72%', 
                    }}   
                  />
                  <CardContent sx={{flex:1, textAlign:'center', pt:1.5, }}>
                    <Stack direction={"column"} spacing={0}>
                      <Typography sx={{pb:0,  fontWeight: 'bold', color: selectedPortal === GroupType.AssetAdmin ? selectColor : "default"}}
                       fontSize={fontSize} fontWeight={fontWeight}>
                        PORTAIL ASSET ADMIN
                      </Typography>
                      <Typography variant="caption">
                        Asset Management Administration
                      </Typography>
                    </Stack>
                        
                  </CardContent>
                </Card>
              {/* </Grid> */}
          {/* </Grid> */}
        </Stack>
    
    
    </div>
    // </Box>
  );
};

export default HomePage;
