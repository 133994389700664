const APP_URL = process.env.REACT_APP_HOST_URL;
const API_URL = process.env.REACT_APP_API_URL;
const PUBLIC_URL = process.env.PUBLIC_URL;
const ADMIN_SITE_URL = process.env.REACT_APP_ADMIN_SITE_URL;
const AGENT_SITE_URL = process.env.REACT_APP_AGENT_SITE_URL;
const CONTACT_SITE_URL = process.env.REACT_APP_CONTACT_SITE_URL;
const DASHBOARD_SITE_URL = process.env.REACT_APP_DASHBOARD_SITE_URL;
const IDENTITY_SERVER_URL = process.env.REACT_APP_IDENTITY_SERVER_URL
const i18n = "i18nn";


const ASSET_AGENT_SITE_URL = process.env.REACT_APP_ASSET_AGENT_SITE_URL;
const ASSET_ADMIN_SITE_URL = process.env.REACT_APP_ASSET_ADMIN_SITE_URL;

export default {
  APP_URL,
  API_URL,
  PUBLIC_URL,
  ADMIN_SITE_URL,
  AGENT_SITE_URL,
  CONTACT_SITE_URL,
  DASHBOARD_SITE_URL,
  IDENTITY_SERVER_URL,
  i18n,
  ASSET_AGENT_SITE_URL,
  ASSET_ADMIN_SITE_URL
};
// Add Config
