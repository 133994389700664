import * as types from "../actions/actionTypes";

const initState = {
  error: null,
  isOpen: false
};

export default function errorReducer(state = initState, action) {
  switch (action.type) {
    case types.SET_ERROR:
      return {
        error: action.error,
        isOpen: true
      };
    case types.HIDE_ERROR:
      return {
        error: null,
        isOpen: false
      };
    default:
      return state;
  }
}
