import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web'

import { privateRouteList, publicRouteList } from "shared/routes";
import ProtectedRoute from "./components/protectedRoute";

const Root = () => {
  const { keycloak, initialized } = useKeycloak()

  const getRoutes = (el) => {
    const routes = (
      <Route element={el.component} path={el.path} key={el.path}>
        {el.items.map((ns) => (
          <>
            {ns.index ? (
              <Route index element={ns.component} />
            ) : (
              <Route element={ns.component} path={ns.path} key={ns.path} />
            )}
          </>
        ))}
      </Route>
    );
    return routes;
  };
  return (
    <Router>
      <Routes>
        {keycloak.authenticated ? (
          <Route path="/" element={<ProtectedRoute />}>
            {privateRouteList.map((route) => {
              return (
                <>
                  <Route
                    element={route.component}
                    path={route.path}
                    key={route.path}
                  />
                  {route.items && route.items.map((el) => getRoutes(el))}
                </>
              );
            })}
          </Route>
        ) : (
          publicRouteList.map((route, index) => (
            <Route element={route.component} path={route.path} key={index} />
          ))
        )}
        {/*{publicRouteList.map((route, index) => (*/}
        {/*  <Route element={route.component} path={route.path} key={index} />*/}
        {/*))}*/}
        {/*<Route path="/" element={<ProtectedRoute />}>*/}
        {/*  {privateRouteList.map((route) => {*/}
        {/*    return (*/}
        {/*      <>*/}
        {/*        <Route*/}
        {/*          element={route.component}*/}
        {/*          path={route.path}*/}
        {/*          key={route.path}*/}
        {/*        />*/}
        {/*        {route.items && route.items.map((el) => getRoutes(el))}*/}
        {/*      </>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</Route>*/}
      </Routes>
    </Router>
  );
};
export default Root;
