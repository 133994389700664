import HomePage from "pages/homePage/HomePage";
import LoginPage from "pages/loginPage/LoginPage";
import LogoutPage from "pages/logoutPage";
import ChangePasswordPage from "pages/changePasswordPage";
import NotFound from "pages/notFound";

import React from "react";

const publicRouteList = [
  {
    path: "/",
    sidebarName: "Login",
    component: <LoginPage />
  },
  {
    path: "/login",
    sidebarName: "Login",
    component: <LoginPage />
  }
];

const privateRouteList = [
  {
    path: "/",
    sidebarName: "Home",
    component: <HomePage />
  },
  {
    path: "/home",
    sidebarName: "Home",
    component: <HomePage />
  },
  {
    path: "/logout",
    sidebarName: "",
    component: <LogoutPage />
  },
  {
    path: "/change-password",
    sidebarName: "",
    component: <ChangePasswordPage />
  }
];

const breadcrumbNameMap = {};

export { publicRouteList, privateRouteList, breadcrumbNameMap };
