import * as types from "./actionTypes";

export function setError(error) {
  return {
    type: types.SET_ERROR,
    error
  };
}

export function hideError() {
  return {
    type: types.HIDE_ERROR
  };
}

export function setLoading() {
  return {
    type: types.SET_LOADING
  };
}

export function hideLoading() {
  return {
    type: types.HIDE_LOADING
  };
}
