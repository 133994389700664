import * as axios from "axios";

import configApp from "../config";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = configApp.API_URL;

axiosClient.interceptors.request.use(
  (config) =>  {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem("token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosClient;
