export const PlatformType = {
  Admin: 0,
  Agent: 1,
  Contact: 2,
  Dashboard: 3,
  AssetAdmin: 4,
  AssetAgent: 5
};

export const GroupType = {
  Admin: 0,
  Agent: 1,
  Contact: 2,
  AdminKB: 3,
  AssetAdmin: 4,
  AssetAgent: 5 
};
