import config from "../config";
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const Logo = `${config.PUBLIC_URL}/assets/logo.png`;

export const LogoNexa = `${config.PUBLIC_URL}/assets/logo_nexa.png`;

export const isEmptyString = (str) => {
  // return !str || str.length === 0;
  return str.length === 0 || !str.trim();
};

export const hasChildren = (item) => {
  const { items: children } = item;
  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }
  if (children.category) {
    return false;
  }

  return true;
};

export const tooltipMsg = (key) => {
  switch (key) {
    case 2:
      return "il n y a une connexion absent";
    case 3:
      return "pas de connexion de entrer";
    case 4:
      return "il n y a pas de connextion entrer sortie";
    case 5:
      return "super";
    default:
      return "Aucune connextion";
  }
};

export const isEmpty = (obj) => {
  for (var prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
};

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const getCurrentTabs = (newValue) => {
  switch (newValue) {
    case 0:
      return "service";
    case 1:
      return "user";
    case 2:
      return "group";
    default:
      return "service";
  }
};

export const startExist = (begin) => {
  if (begin) return true;
  else return false;
};
export const intermediateExist = (inter) => {
  if (inter) return true;
  else return false;
};
export const finalExist = (end) => {
  console.log("end :>> ", end);
  if (end) return true;
  else return false;
};

export const isValidWF = (idFin, idDebut, edges) => {
  let isValid = true;
  if (edges.length === 0) {
    isValid = false;
    return false;
  }
  let edgesF = edges.filter((el) => el.target === idFin);

  if (edgesF.length === 0) return false;
  let result;

  for (let e = 0; e < edgesF.length; e++) {
    const element = edgesF[e];
    if (element.source === idDebut && element.target === idFin) {
      return true;
    } else {
      result = isValidWF(element.source, idDebut, edges);
    }
  }
  return (isValid = result && isValid);
};
// Validate the WorkFlow with N Final Step
export const iterateFinal = (idFins, idDebut, edges) => {
  let validateWF;
  for (let k = 0; k < idFins.length; k++) {
    const id = idFins[k];
    validateWF = isValidWF(id, idDebut, edges);
    if (!validateWF) {
      console.log(validateWF);
      break;
    }
  }
  return validateWF;
};

export const checkExistNodeConnexion = (id, edgeConnexion) => {
  let connectedSource = false;
  let connectedTarget = false;
  for (let k = 0; k < edgeConnexion.length; k++) {
    const element = edgeConnexion[k];
    if (id === element.source) {
      connectedSource = true;
    }
    if (id === element.target) {
      connectedTarget = true;
    }
  }

  return connectedSource || connectedTarget;
};

export const checkIntermediateConnected = (elements, edgeConnexion) => {
  let intermediateStep = elements.filter(
    (els) => els.type === "interCustomNode"
  );

  let connected = true;
  if (intermediateStep) {
    for (let k = 0; k < intermediateStep.length; k++) {
      const step = intermediateStep[k];
      connected = checkExistNodeConnexion(step.id, edgeConnexion);
    }
  }

  return connected;
};
export const checkBeginEdge = (elements, edgeConnexion) => {
  let intermediateStep = elements.filter(
    (els) => els.type === "startCustomNode"
  );

  let connected = true;
  if (intermediateStep) {
    for (let k = 0; k < intermediateStep.length; k++) {
      const step = intermediateStep[k];
      connected = checkExistNodeConnexion(step.id, edgeConnexion);
    }
  }
  return connected;
};
export const checkFinalEdge = (elements, edgeConnexion) => {
  let intermediateStep = elements.filter(
    (els) => els.type === "finalCustomNode"
  );

  let connected = true;
  if (intermediateStep) {
    for (let k = 0; k < intermediateStep.length; k++) {
      const step = intermediateStep[k];
      connected = checkExistNodeConnexion(step.id, edgeConnexion);
    }
  }
  return connected;
};

export const getFullName = (user) => {
  return `${user?.firstName || ""} ${user?.lastName || ""}`;
};
